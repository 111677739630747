import { NativeConfigWebviewExtraDataRegisterTelehealth } from '@global/utils/native/native-config';
import { CustomWebviewMessageForScheduleAppointment } from '@global/utils/native/native-custom-webview-message';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { ScheduleFormRowWrapper } from '@lp-root/src/modules/telehealth/schedule-page/schedule-form-row-wrapper.component';
import { NativeHelper } from '@web/data/native.helper';
import { createInsomniacDataSource } from '@web/data/vigilantes.datasource.factory';
import * as React from 'react';

const SignUpForUserTelehelathFromProfilePage: React.FunctionComponent = () => {
  const datasource = React.useRef(createInsomniacDataSource());
  const userId = NativeHelper.userId;

  const {
    first_name,
    last_name,
    email,
    birthdate,
    phone,
    document,
    hcp_id: hcpId,
    hcp_name,
    appointment_date,
    has_dav_id,
    next_available_appointment_date,
    appointment_time,
  } = (NativeHelper.extraData as NativeConfigWebviewExtraDataRegisterTelehealth) ?? {};

  const handleScheduleSuccess = () => {
    const msg: CustomWebviewMessageForScheduleAppointment = {
      type: 'custom',
      id: 'success',
    };
    NativeHelper.postMessage(msg);
  };

  const handleHcpBusyError = () => {
    const msg: CustomWebviewMessageForScheduleAppointment = {
      type: 'custom',
      id: 'hcp_busy',
    };
    NativeHelper.postMessage(msg);
  };

  const signUpData = {
    initialBirthdate: birthdate,
    initialDocument: document,
    initialEmail: email,
    initialFirstName: first_name,
    initialLastName: last_name,
    initialPhone: phone,
  };

  return (
    <>
      <ScheduleFormRowWrapper
        onHcpAlreadyBusyError={handleHcpBusyError}
        datasource={datasource.current}
        userId={userId}
        hcpId={hcpId}
        hcpName={hcp_name}
        nextAvailableAppointmentDate={next_available_appointment_date}
        userHasDavId={!!has_dav_id}
        onScheduleSuccess={handleScheduleSuccess}
        scheduleDate={
          appointment_date && appointment_time
            ? {
                date: new Date(appointment_date),
                time: appointment_time,
              }
            : null
        }
        signUpData={verifyIfHasUserSignUpInfo(signUpData) ? signUpData : null}
      />
    </>
  );
};

export default SignUpForUserTelehelathFromProfilePage;

interface SignUpDataInput {
  initialFirstName: string;
  initialLastName: string;
  initialEmail: string;
  initialBirthdate: string;
  initialPhone: string;
  initialDocument: string;
}

const verifyIfHasUserSignUpInfo = (input: SignUpDataInput) => {
  return !isNullOrUndefined(Object.keys(input).find((key) => !!input[key]));
};
